
    <template>
      <section class="content element-doc">
        <h2>Loading 加载</h2>
<p>加载数据时显示动效。</p>
<h3>整页加载</h3>
<p>页面数据加载时显示。</p>
<demo-block>
        <div><p>当使用指令方式时，全屏遮罩需要添加<code>fullscreen</code>修饰符（遮罩会插入至 body 上），此时若需要锁定屏幕的滚动，可以使用<code>lock</code>修饰符；当使用服务方式时，遮罩默认即为全屏，无需额外设置。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-button
    type=&quot;primary&quot;
    @click=&quot;openFullScreen1&quot;
    v-loading.fullscreen.lock=&quot;fullscreenLoading&quot;
  &gt;
    指令方式
  &lt;/el-button&gt;
  &lt;el-button type=&quot;primary&quot; @click=&quot;openFullScreen2&quot;&gt; 服务方式 &lt;/el-button&gt;
&lt;/template&gt;

&lt;script&gt;
  import { ref } from 'vue'
  import { ElLoading } from 'element3'
  export default {
    setup() {
      let fullscreenLoading = ref(false)
      function openFullScreen1() {
        fullscreenLoading.value = true
        setTimeout(() =&gt; {
          fullscreenLoading.value = false
        }, 2000)
      }
      function openFullScreen2(e) {
        const loading = ElLoading.service({
          // lock: true,
          // target: e.target,
          text: 'Loading...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        setTimeout(() =&gt; {
          loading.close()
        }, 2000)
      }

      return { fullscreenLoading, openFullScreen1, openFullScreen2 }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>服务</h3>
<p>Loading 还可以以服务的方式调用。引入 Loading 服务：</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">import</span> { ElLoading } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;element3&#x27;</span>
<span class="hljs-keyword">import</span> { nextTick } <span class="hljs-keyword">from</span> <span class="hljs-string">&#x27;vue&#x27;</span>
</code></pre>
<p>在需要调用时：</p>
<pre><code class="hljs language-javascript">ElLoading.service(options)
</code></pre>
<p>其中 <code>options</code> 参数为 Loading 的配置项，具体见下表。<code>ElLoading.service</code> 会返回一个 Loading 实例，可通过调用该实例的 <code>close</code> 方法来关闭它：</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">let</span> loadingInstance = ElLoading.service(options)
nextTick(<span class="hljs-function">() =&gt;</span> {
  <span class="hljs-comment">// 以服务的方式调用的 ElLoading 需要异步关闭</span>
  loadingInstance.close()
})
</code></pre>
<p>需要注意的是，以服务的方式调用的全屏 ElLoading 是单例的：若在前一个全屏 ElLoading 关闭前再次调用全屏 ElLoading，并不会创建一个新的 ElLoading 实例，而是返回现有全屏 ElLoading 的实例：</p>
<pre><code class="hljs language-javascript"><span class="hljs-keyword">let</span> loadingInstance1 = ElLoading.service({ <span class="hljs-attr">fullscreen</span>: <span class="hljs-literal">true</span> })
<span class="hljs-keyword">let</span> loadingInstance2 = ElLoading.service({ <span class="hljs-attr">fullscreen</span>: <span class="hljs-literal">true</span> })
<span class="hljs-built_in">console</span>.log(loadingInstance1 === loadingInstance2) <span class="hljs-comment">// true</span>
</code></pre>
<p>此时调用它们中任意一个的 <code>close</code> 方法都能关闭这个全屏 Loading。</p>
<p>:::</p>
<h3>Options</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>target</td>
<td>Loading 需要覆盖的 DOM 节点。可传入一个 DOM 对象或字符串；若传入字符串，则会将其作为参数传入 <code>document.querySelector</code>以获取到对应 DOM 节点</td>
<td>object/string</td>
<td>—</td>
<td>document.body</td>
</tr>
<tr>
<td>body</td>
<td>同 <code>v-loading</code> 指令中的 <code>body</code> 修饰符</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>fullscreen</td>
<td>同 <code>v-loading</code> 指令中的 <code>fullscreen</code> 修饰符</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>lock</td>
<td>同 <code>v-loading</code> 指令中的 <code>lock</code> 修饰符</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>text</td>
<td>显示在加载图标下方的加载文案</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>spinner</td>
<td>自定义加载图标类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>background</td>
<td>遮罩背景色</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>customClass</td>
<td>Loading 的自定义类名</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, resolveDirective: _resolveDirective, withCtx: _withCtx, createVNode: _createVNode, withDirectives: _withDirectives, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _withDirectives(_createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.openFullScreen1
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 指令方式 ")
      ]),
      _: 1
    }, 8, ["onClick"]), [
      [
        _directive_loading,
        _ctx.fullscreenLoading,
        void 0,
        {
          fullscreen: true,
          lock: true
        }
      ]
    ]),
    _createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.openFullScreen2
    }, {
      default: _withCtx(() => [
        _createTextVNode(" 服务方式 ")
      ]),
      _: 1
    }, 8, ["onClick"])
  ], 64))
}
  
    
        const { ref } = Vue
        
  
            const { ElLoading } = Element3
          
  const democomponentExport = {
    setup() {
      let fullscreenLoading = ref(false)
      function openFullScreen1() {
        fullscreenLoading.value = true
        setTimeout(() => {
          fullscreenLoading.value = false
        }, 2000)
      }
      function openFullScreen2(e) {
        const loading = ElLoading.service({
          // lock: true,
          // target: e.target,
          text: 'Loading...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        setTimeout(() => {
          loading.close()
        }, 2000)
      }

      return { fullscreenLoading, openFullScreen1, openFullScreen2 }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  